import Cookies from 'js-cookie'
import { useEffect } from 'react'

const EXEC_WEB_APP_URL = 'https://app.withdouble.com'
const ASSITANT_WEB_APP_URL = 'https://dashboard.withdouble.com'

const Login = () => {
  useEffect(() => {
    const userType = Cookies.get('double-app-user-type')
    window.location.href =
      userType === 'assistant' ? ASSITANT_WEB_APP_URL : EXEC_WEB_APP_URL
  }, [])

  return null
}

export default Login
